<template>
  <div>
    <v-container fluid>

      <v-row>
        <v-col cols="12">
          <v-card class="shadow rounded-lg">
            <v-card-text class="pa-10 text-lg-start text-center">
              <v-row align="center">
                <v-col>

                  <h2 class="font-weight-medium py-4 grey--text text--darken-3">
                    Bienvenue à Eagle eye.
                  </h2>

                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse pellentesque sit amet turpis
                    quis consectetur.
                  </p>

                  <v-btn color="primary" depressed to="/supervisor-view/campaigns">
                    Ce qui est nouveau !
                  </v-btn>

                </v-col>

                <v-col class="text-lg-end text-center">
                  <img :src="require('@/assets/eagle.svg')" alt="" width="220"/>
                </v-col>

              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>